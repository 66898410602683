@import './colors.scss';

body{
    height: auto;
}

body::-webkit-scrollbar {
    width: 12px;
}

body::-webkit-scrollbar-track{
    background: rgba($primary, 0.25);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

body::-webkit-scrollbar-thumb {
    background-color: rgba($primary, 0.8);
    border-radius: 00px;
}

.root{
    //height: 100%;
}

.App{
    //height: 100%;
}

.main-btn {
    &.get-started {
        color: white !important;
    }

    &.get-started:hover {
        color: #3A3ABF !important;
    }
}

.react-switch-checkbox {
    height: 0;
    width: 0;
    position: absolute;
    visibility: hidden;
}

.react-switch {
    cursor: pointer;
    width: 75px;
    height: 32px;
    background: #2b2b2b;
    background: linear-gradient(90deg, rgba(24, 24, 80, 0.8), rgba(53, 53, 124, 0.8) 50%, rgba(80, 80, 154, 0.8));
    background: rgb(20, 10, 110);
    position: relative;
    transition: background-color 0.2s;
    margin-bottom: 0px;
    border-radius: 26px;
}

.react-switch .react-switch-button {
    position: absolute;
    z-index: 1;
    top: 5px;
    left: 4px;
    width: 35px;
    border-radius: 27px;
    height: 22px;
    transition: 0.2s;
    background: #181818;
    background: linear-gradient(90deg, rgba(40, 40, 114, 0.8), rgba(60, 60, 134, 0.8) 50%, rgba(80, 80, 154, 0.8));
    background: rgb(20, 10, 110);
    background: $new-primary-navbar;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked+.react-switch-button {
    left: calc(100% - 4px);
    transform: translateX(-100%);
}

.react-switch-checkbox:active+.react-switch-button {
    width: 40px;
}

.react-switch-labels {
    display: relative;
    z-index: 0;
    height: 100%;
    font-size: 16px;
}

.react-switch-labels span {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;

    i{
        height: 25px;
        width: 25px;
    }

    i.moon{
        color: #fff;
        text-shadow: 0px 0px 11px #fff;
    }

    i.sun{
        color: #ffb410;
        text-shadow: 0px 0px 16px #ffb410;
    }
}

.react-switch-labels span:first-child {
    left: 0;
}

.react-switch-labels span:last-child {
    right: 0;
}

@media (max-width: 1434px){
    .sign-buttons{
        margin-left: 33px;
        padding-bottom: 15px;
        
        .buttonSignUp{
            margin-left: 0px;
        }
    }

    .main-btn {
        &.buttonWhite{
            line-height: 36px;
            padding: 0 12px;
            font-size: 15px;
        }

        &.buttonSignUp{
            line-height: 36px;
            padding: 0 12px;
            font-size: 15px;
        };
    }

    .react-switch{
        margin-left: 33px;
        margin-bottom: 5px;
    }
}

@media (max-width: 991px){
    .sign-buttons{
        margin-left: 25px;
        
        .buttonSignUp{
            margin-left: 0px;
        }
    }

    .react-switch{
        margin-left: 25px;
        margin-bottom: 5px;
    }
}

#blue p{
    &.get-started{
        font-size: 24px;
    }
}

/********SPINNER*********/

.containerSpinnerGeneral{
    display: flex;
    height: 100%;
    position: absolute;
    width: 100%;
  
    .segmentSpinner{
        flex-grow: 1;
    }
  
    overflow: hidden;
}
  
.containerSpinnerSecondary{
    .dimmer{
      background: none !important;
      border: none !important;
    }
}
  
.ui.dimmer .ui.loader{
    color: #E5AC69 !important; 
    font-weight: 500;
    font-size: 1.8rem !important;
}
  
.ui.dimmer .ui.loader:before{
    border-color: #E5AC69 !important; 
}
  
.ui.dimmer{
    background: rgba(0,0,0,.60);
}
  
.Spinner__Body--open{
    overflow: hidden;
}

/******END-SPINNER******/

/*****NAVBAR*****/

.navWebTenders{
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 80px;
    background: $new-primary-navbar;
    border-bottom: 1px solid #6C6C6C;
    padding-left: 32px !important;
    padding-right: 32px !important;
    opacity: 1.5;
}

.ui.segment{
    margin-bottom: 0px;
}

.ui.segment.active.tab{
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    flex-direction: column;
}

.ui.inverted.segment, .ui.primary.inverted.segment{
    background-color: $new-primary-navbar;
    border-radius: 0px;
    height: 80px;
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: 0px 3px 12px 6px rgba(0, 0, 0, 0.15);
    
    .menu{
        height: -webkit-fill-available;
        border: none;
    }

    .item{
        height: -webkit-fill-available; 
    }
}

.ui.secondary.inverted.menu .link.item, .ui.secondary.inverted.menu a.item{
    color: #ffffff !important;
    margin: 0;
    padding-left: 24px;
    padding-right: 24px;
    font-weight: 700;
}

.ui.secondary.inverted.menu .dropdown.item:hover, .ui.secondary.inverted.menu .link.item:hover, .ui.secondary.inverted.menu a.item:hover{
    color: $secondary !important;
    transition: 0.3s;
}

.ui.menu .item>img:not(.ui){
    width: 7.5em;
}

.navbar-tabs{
    font-size: 16px;
    padding-left: 16%;
}

.ui.secondary.inverted.pointing.menu .active.item{
    color: $secondary !important;
    box-shadow: inset 0 -4px 0  $secondary;
    border: none;
}

.dropdownTitle{
    .dropdown-toggle{
        color: #fff !important;
    }

    .dropdown-menu.show{
        background: #3A3ABF;
        min-width: 110px;
        width: 110px;
        box-shadow: 0 3px 12px -6px black;
        display: inline-block;
        left: 10px;
        display: flex;
        flex-direction: column;
        padding: 8px 0px 5px 0px;

        .dropdown-item{
            padding: 0.25rem 3px;
            font-weight: 600;
            font-size: 13.5px;
            text-align: center;
            color: white;
            border-bottom: 2px solid transparent;
            width: fit-content;
            margin: 4px auto;
            
            &.sign-out{
                padding-top: 10px;
                border-top: 2px solid #4c4cc5;
                font-size: 14px;
            }
        }

        .dropdown-item:hover{
            background: #3A3ABF;
            border-bottom: 2px solid $secondary;
            
        }

        .dropdown-item:focus{
            background: #3A3ABF;
        }
    }
}

@media (max-width: 1434px){
    .nav-coowise{
        padding-bottom: 10px !important;
    }

    .dropdownTitle{
        padding-bottom: 10px;
        .dropdown-toggle{
            margin-left: 25px;
            padding-left: 0.5rem;
        }
    }

    .dropdown-menu.show{
        top: 85%;
        left: 25px !important;
    }
}

@media (max-width: 992px){
    .dropdownTitle{
        .dropdown-toggle{
            padding-left: 0rem;
        }
    }

    .dropdown-menu.show{
        left: 22px !important;
    }
}

@media (min-width: 992px){
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    .navbar-expand-lg .navbar-toggler {
        display: block;
    }

    .navbar-expand-lg .navbar-nav{
        flex-direction: column;
    }
}

@media (min-width: 1435px){
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-lg .navbar-toggler {
        display: none;
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex!important;
        flex-basis: auto;
    }

    .navbar-expand-lg .navbar-nav{
        flex-direction: row;
    }
}

@media (max-width: 1434px){
    .nav-coowise {
        padding-top: 0px !important;
        padding-bottom: 20px;
    }
}

/*****END-NAVBAR***/

.ui.button.buttonPrimary{
    background: $primary;
    color: #ffffff;
    padding: 12px 16px 12px 16px;
    box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.15);
    font-size: 1rem;
    
    i{
        opacity: 1 !important;
    }
}

.ui.button.buttonPrimary:hover{
    background: $button-hover;
    transition: 0.3s !important;
}

/********SIGN UP********/

.hr-divider{
    margin-top: 20px;
    margin-bottom: 20px;
}

.divPhoneNumber{
    display: flex !important;
    flex-direction: row !important;
}

.labelInput{
    font-size: 1rem;
    font-weight: 700;
    color: $text-primary;
    margin-top: 16px !important;
    margin-bottom: 8px !important;
    display: flex;
}

label.labelIncompleteData{
    font-size: 0.9rem !important;
    color: $button-error-hover !important;
    margin-top: 10px !important;
    margin-bottom: 0px !important;
}

.form-check-input{
    cursor: pointer;
}

/**********MODALS*************/

.ReactModal__Body--open{
    overflow: hidden;
}

.ReactModal__Overlay{
    background: rgba(#333333, 0.6) !important;
    display: flex !important;
    z-index: 3;
}

.ReactModal__Content{
    padding: 24px !important;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 3px 8px 5px rgba(0, 0, 0, 0.2);
    margin: auto;
    display: flex;
    flex-direction: column;

    .titleModal{
        color: $primary;
        font-size: 1.7em;
    }

    .subTitleModal{
        color: $primary;
        font-size: 1.2em;
    }
}

.modal{
    position: relative;

    &.termsAndConditions{
        width: 600px !important;
        height: 650px !important;
    }

    &.error{
        width: 350px !important;
        height: 250px !important;
    }

    &.changeSubscription{
        width: 550px !important;
        height: auto !important;

        &.fetching{
            height: 300px !important;
        }

        .contentModal{
            margin-top: 20px;

            p{
                padding: 12px 16px 12px 16px;
                font-size: 18px;
                line-height: 1.5;
                text-align: center;
            }


            p.successfullChangeSubscription{
                font-size: 25px;
                font-weight: 550;
                color: $primary;
            }

            p.failedChangeSubscription{
                font-size: 25px;
                font-weight: 550;
                color: $error
            }

            p.infoMessage{
                margin-top: 15px;
                font-size: 13px;
            }

            a{
                color: #E5AC69;
                font-size: 20px;
                font-weight: 500;
            }

            .span{
                color: $primary;
                font-size: 20px;
                font-weight: 500;
            }
        }
    }

    &.article{
        display: flex;
        padding: 0 !important;
        max-width: 65%;
        width: auto;
        height: auto;
    }

    &.container{
        padding: 0 !important;
        height: auto;

        .closeModal{
            margin-bottom: -44px;

            i {
                color: black;
                font-size: 1.8em;
            }
                
            i:hover {
                color: black !important;
            }
        }
    }

    &.vertical{
        padding: 0 !important;
        height: auto;
        max-width: 35%;
    }

    &.horizontal{
        padding: 0 !important;
        height: auto;
    }

    &.nav{
        float: left !important;
        height: 100% !important;
        left: 0 !important;
        right: auto;
        top: 82px !important;
        transform: none;
        width: 250px;
        margin: 0px !important;
        padding-right: 0px !important;
        border-top-left-radius: 0px;

        .api-reference-nav{
            overflow: auto;
            padding: 40px 0px 0px 0px;
            width: 250px !important;
            height: 100%;
            overflow-y: auto;
            background: #ffffff;
            position: fixed;
            top:32;
            left:0;
            margin: 0px !important;
            max-width: 250px !important;

            h1{
                font-size: 13px;
                font-weight: 700;
                letter-spacing: .08em;
                line-height: 16px;
                text-transform: uppercase;
                color: #202123;
                margin-bottom: 10px;
                padding: 0px 24px;
            }

            h2{
                font-size: 15px;
                line-height: 20px;
                color: #6e6e80;
                padding: 6px 24px;
                text-decoration: none;
                font-weight: 500;
                cursor: pointer;
            }

            .nav-item{
                &.active{
                    background: rgba($secondary, 0.2) !important;
                    color: $secondary !important;
                }
            }

            .nav-item:hover{
                background: #f7f7f8;
                color: #353740;
            }
        }
    }
}

@media (max-width: 325px){
    .modal{
        position: fixed;
        top: 50%;
        left: 15%;

        &.container{    
            .closeModal{
                i{
                    font-size: 1.3em !important;
                }
            }
        }
    }
}

@media (max-width: 576px){
    .modal{
        &.horizontal{
            max-width: calc(100% - 80px);
            min-width: 230px;

            .closeModal{
                i{
                    font-size: 1.4em !important;
                }
            }
        }

        &.vertical{
            max-width: calc(100% - 120px);
            min-width: 230px;

            .closeModal{
                i{
                    font-size: 1.4em !important;
                }
            }
        }
    }
}

@media (min-width: 576px){
    .modal{
        &.horizontal{
            max-width: 510px;

            .closeModal{
                i{
                    font-size: 1.5em !important;
                }
            }
        }
    }
}

@media (min-width: 768px){
    .modal{
        &.horizontal{
            max-width: 690px;

            .closeModal{
                i{
                    font-size: 1.6em !important;
                }
            }
        }
    }
}

@media (min-width: 992px){
    .modal{
        &.horizontal{
            max-width: 930px;

            .closeModal{
                i{
                    font-size: 1.7em !important;
                }
            }
        }
    }
}

@media (min-width: 1200px){
    .modal{
        &.horizontal{
            max-width: 1110px;

            .closeModal{
                i{
                    font-size: 1.8em !important;
                }
            }
        }
    }
}

@media (min-width: 576px){
    .modal{
        &.vertical{
            max-width: 400px;

            .closeModal{
                i{
                    font-size: 1.5em !important;
                }
            }
        }
    }
}

@media (min-width: 768px){
    .modal{
        &.vertical{
            max-width: 450px;

            .closeModal{
                i{
                    font-size: 1.6em !important;
                }
            }
        }
    }
}

@media (min-width: 992px){
    .modal{
        &.vertical{
            max-width: 500px;

            &.reduce-width{
                max-width: 475px;
                margin: auto;
                margin-top: 115px;
            }

            .closeModal{
                i{
                    font-size: 1.7em !important;
                }
            }
        }
    }
}

@media (min-width: 1200px){
    .modal{
        &.vertical{
            max-width: 550px;

            &.reduce-width{
                max-width: 475px;
                margin: auto;
                margin-top: 115px;
            }

            .closeModal{
                i{
                    font-size: 1.8em !important;
                }
            }
        }
    }
}

.labelInput{
    font-size: 1rem;
    font-weight: 700;
    color: $text-primary;
    margin-top: 16px !important;
    margin-bottom: 8px !important;
    display: flex;
}

.valueInput{
    font-size: 1rem;
    font-weight: 400;
    color: $text-primary;
    margin-bottom: 16px !important;
}

.modalHeader{
    margin-bottom: 16px;
    margin-left: 0px;
    box-shadow: 0px 1px #22242626;
}

.modalFooter{
    padding: 20px 24px;
    float: right;
    width: 100%;
    border-top: 1px solid $lines;
    bottom: 0;
    position: absolute;
    left: 0;
    text-align: right;
    display: flex;
    flex-direction: row;
    background: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .divEspaciado{
        flex-grow: 1;
    }

    .buttonPrimary{
        margin-left: 20px;
    }

    .buttonLabel{
        color: $text-secondary !important;
        transition: 0.1s;
    }

    .buttonLabelModal{
        float: left;
        color: $primary !important;
    }

    .buttonLabelModal:hover{
        background-color: rgba($primary,0.2) !important;        
        padding: 4px  8px !important;
        margin: -4px -8px !important;
    }

    .buttonLabel:hover{
        color: $primary !important;
    }
}

.ui.button.buttonPrimary{
    background: $primary;
    color: #ffffff;
    padding: 12px 16px 12px 16px;
    box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.15);
    font-size: 1rem;
    
    i{
        opacity: 1 !important;
    }
}

.ui.button.buttonPrimary:hover{
    background: $button-hover;
    transition: 0.3s !important;
}

.ui.button.buttonLabel{
    transition: 0.2s;
}

.ui.button.buttonLabel:hover{
    background: rgba($primary, 0.2) !important;
    padding: 4px 8px !important;
    margin: -4px -8px !important;
}

.buttonLabel{
    background: none !important;
    white-space: nowrap;
    text-transform: capitalize;
    margin: 0px !important;
    padding: 0px !important;
    color: $primary !important;
    border: none;
    font-weight: 700;
}

.closeModal{
    background: none !important;
    text-align: right;
    padding-right: 0px !important;
    i{
        font-size: 1.3em;
        opacity: 1;
        color: $text-secondary;
        
    }

    i:hover{
        color: $primary !important;
    }
}

.closeModal:hover{
    transition: 0.3s !important;
    i{
        transition: 0.3s !important;
        color: $primary;
    }
}

.containerModal{
    flex-grow: 1;
    overflow: scroll;
    padding-bottom: 64px;

    &.changeSubscription{
        overflow: hidden !important;

        &.without-footer{
            padding-bottom: 20px;
        }
    }
}

.gridColumn{
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

/**********END-MODALS*************/

/**********SNACKBAR***************/

.mdc-snackbar__surface{
    justify-content: space-between;
    min-width: auto;

    .mdc-snackbar__label{
        width: auto;
    }

    .mdc-snackbar__actions{
        button{
            padding: 10px 0px 10px 15px;
        }
    }
}

.toastSuccess .mdc-snackbar__surface{
    background: $success; 
}

.infoToast .mdc-snackbar__surface{
    background: $secondary; 
}

.errorToast .mdc-snackbar__surface{
    background: $error; 
}

.mdc-snackbar{
    top: 100px;
    position: fixed;
    bottom: auto !important;
}

.mdc-snackbar__label{
    font-weight: 700 !important;
}

.mdc-snackbar__action{
    background: none;
    color: #fff !important;
    border: none;
}

/**********END-SNACKBAR***************/

.containerUnsubscribe{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-self: center;
    flex-grow: 1;
    height: calc(100vh - 100px);
    width: 50%;
    margin: 0 auto;
}

.titleUnsubscribe{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 12px;
    color: $primary;
    margin-top: 0px;
    margin-bottom: 8px;
}

.paragraphUnsubscribe{
    font-size: 16px;
    color: #989898;
    margin-bottom: 10px;
}

.buttonEmptyState{
    width: 125px;
    margin: 0 auto !important;
}

.containerPlanSelected{
    outline: 4px solid $lines;
    outline-color: $secondary;
}

.containerExpiredPlanSelected{
    border: 4px solid $lines;
    color: $button-error-hover !important;
    border-color: $button-error-hover;
}

.cursor{
    cursor: pointer;
}

.blue{
    color: blue;
}

/************FOOTER***************/

.description-contact-footer{
    color: #fff;
}

.description-contact-footer:hover{
    color: $secondary;
}

/**********END-FOOTER*************/

/**************HEADER**************/

#home{
    overflow-x: hidden;
    background: linear-gradient(90deg, rgb(25, 20, 41) 3%, rgb(20, 10, 30) 15%, rgb(20, 10, 110) 85%, rgb(40, 40, 114) 99%);

    .header-hero{
        background: linear-gradient(90deg,#282872cc 0,#3c3c86cc 50%,#50509acc);
    }
}

.headerCol{
    padding-top: 10px;
}

/************END-HEADER************/


/**************CONTACTUS**************/

#contact{
    background: linear-gradient(90deg, rgb(25, 20, 41) 3%, rgb(20, 10, 30) 15%, rgb(20, 10, 110) 85%, rgb(40, 40, 114) 99%);
}

.PhoneInput{
    height: 30px;
    .PhoneInputInput{
        background-color: #F7F7F7 !important;
        border-color:#E5AC69;
        border-width: 0px;
    }
}

.divSpinnerContact{
    height: 200px ;
}

/************END-CONTACTUS************/

.navCoowise{
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 82px;
    background: $new-primary-navbar;
    padding-left: 32px;
    padding-right: 32px;
    opacity: 1.5;
    box-shadow: 0 3px 12px -6px black;
    align-content: center;
}

.navBlog{
    position: relative !important;
    flex: 0 1 auto;
}

.navArt{
    flex: 0 1 auto;
}

@media (max-width: 767px) {
    .navBlog {
        padding: 10px !important;
    }

    .navArt{
        padding: 10px !important;
    }
}

.navbar-nav{
    margin: 0 auto !important;
}
  
.navbar-light .navbar-nav .nav-link{
color: #fff !important;
}

@media only screen and (max-width: 767px) {
    .navCoowise{
      padding: 0px;
    }
  
    .navbar-brand{
      margin-left: 16px;
    }
  
    .navbar-toggler{
      margin-right: 16px;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  
    .navbar-collapse{
      padding: 16px;
    }
  
    .navCoowise{
      z-index: 5;
  
      .navbar-collapse{
        background: #26266b;
  
        .imgNav{
          width: 10%;
          height: 10%;
          padding-bottom: 2.7px;
          padding-left: 3.5px;
        }
      }
    }
}

.nav-coowise{
    justify-content: center;
    flex: 1;
    padding-top: 20px;
    padding-bottom: 20px;

    &.blog{
        padding-right: 120px;
    }
  
    .nav-link{
      margin-right: 25px;
      margin-left: 25px;
      font-size: 16px;
      font-weight: bold;
      white-space: nowrap;
      display: inline-block;
      border-bottom: 2px solid transparent;

      &.selected{
        border-bottom: 2px solid $secondary;
      }
    }
  
    .nav-link:hover{
      border-bottom: 2px solid $secondary;
    }
}

.not-cursor{
    cursor: auto !important;
}

.card-header{
    button:hover{
        background-color: white !important;
    }
}

/**********COOKIES-POLICY***************/

.privacy-section{
    padding-top: 100px;
    padding-bottom: 100px;

    hr{
        margin-top: 15px !important;
        margin-bottom: 15px !important;
    }

    h1{
        margin: 0px;
    }

    h2{
        margin: 0px;
    }

    h4{
        margin: 0px;
        margin-bottom: 35px;
    }

    .privacy-row{
        margin-top: 40px;
    }

    ul{
        list-style-type: disc;
        padding-inline-start: 40px;
    }

    ol{
        list-style-type: lower-alpha;
        padding-inline-start: 40px;
    }
}

.line-title{
    border-top: 1px solid $primary;
    width: 60px;
    margin-left: 0px;
}

.title-cookies-policy{
    color: $primary;
    font-size: 50px;
    padding-bottom: 20px;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    margin: 5px;
    font-weight: 500;
    line-height: 1.2;
}

.title-section-cookies-policy{
    color: $primary;
    font-size: 40px;
    padding-bottom: 20px;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    margin: 5px;
    font-weight: 500;
    line-height: 1.2;
}
  
.text-below-title{
    font-size: 18px;
    color: #000000;
}
  
.summary-item{
    font-size: 20px;
    color: #000000;
    line-height: 30px;
    margin-bottom: 5px;

    &.privacy{
        margin-bottom: 15px;
    }
}
  
.cookie-policy-paragraph{
    font-size: 20px;
    color: #000000;
    line-height: 30px;
    margin-bottom: 20px;
}
  
.policy-subtitle{
    font-size: 28px;
    color: $primary;
    margin-bottom: 30px !important;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    margin: 5px;
    font-weight: 500;
    line-height: 1.2;
}
  
.sub-columns{
    margin-top: 50px;

    &.privacy{
        margin-top: 20px;

        &.second{
            margin-top: 40px
        }
    }
}
  
.list-margin{
    margin-bottom: 20px;
}

.divtable {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 20px;

    .cookies-policy-table {
        width: auto;
        max-width: 100%;
        margin-bottom: 35px;
        border: 1px solid #e2e0e0;
        margin-left: auto;
        margin-right: auto;

        .tr-head {
            background-color: $primary;
        }

        .thead-td-table {
            padding: 10px 50px 10px 10px;
        }

        .tbody-td-table {
            padding: 10px 50px 10px 10px;
        }

        .thead-text {
            font-weight: 500;
            font-size: 18px;
            color: white;
            line-height: 30px;
        }

        .tbody-text {
            font-size: 16px;
            color: #000000;
        }
    }
}

@media only screen and (max-width: 767px) {
    .privacy-section{
        padding-bottom: 50px;
    }
}

/***********END-COOKIES-POLICY**********/

/*************BLOG*******************/

.blog-div{
    display: flex;
    flex-flow: column;
    height: 100%;

    &.padding-top{
        padding-top: 85px;
    }
}

.art-section{
    flex: 1 1 auto;
    padding: 114px 32px 64px 32px;
    background: #f0eeee;

    &.api-reference{
        padding: 0px;
    }

    .api-reference-body{
        display: flex;
        flex-direction: row;
        margin: 0px;
        padding: 0px;
        max-width: 100%;
        width: 100%;

        .api-reference-nav{
            overflow: auto;
            padding: 132px 0px 0px 0px;
            width: 250px;
            height: 100%;
            overflow-y: auto;
            background: #ffffff;
            border-right: 1px solid #ececf1;
            position: fixed;
            top:0;
            left:0;

            h1{
                font-size: 13px;
                font-weight: 700;
                letter-spacing: .08em;
                line-height: 16px;
                text-transform: uppercase;
                color: #202123;
                margin-bottom: 10px;
                padding: 0px 24px;
            }

            h2{
                font-size: 15px;
                line-height: 20px;
                color: #6e6e80;
                padding: 6px 24px;
                text-decoration: none;
                font-weight: 500;
            }

            h3{
                font-size: 15px;
                line-height: 20px;
                color: #6e6e80;
                padding: 6px 24px 6px 36px;
                text-decoration: none;
                font-weight: 400;

            }

            h4{
                font-size: 15px;
                line-height: 20px;
                color: #6e6e80;
                padding: 6px 24px 6px 48px;
                text-decoration: none;
                font-weight: 400;

            }

            .nav-item{
                &.active{
                    background: rgba($secondary, 0.2) !important;
                    color: $secondary !important;
                }
            }

            .nav-item:hover{
                background: rgba($secondary, 0.2);
                color: #353740;
                cursor: pointer;
            }
        }
    }

    .art-container{
        background: #ffffff;
        padding: 30px;

        &.api-reference{
            padding: 132px 300px 50px 300px;
            margin: 0px 0px 0px 250px;
            overflow-y: auto;
            flex: 1 1 auto;
            height: 100%;
            max-width: 100%;
        }

        &.article{
            padding: 132px 225px 50px 225px;
            margin: 0px 0px 0px 250px;
            overflow-y: auto;
            flex: 1 1 auto;
            height: 100%;
            max-width: 100%;

            .art-section{
                background: white;

                &.articles{
                    padding: 0px;
                }
            }
        }

        .title-row{
            flex-direction: column;

            .title-col {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .navigation{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    padding-inline-start: 5px;
                    font-size: 15px;
                    align-items: center;

                    li+li:before {
                        color: #000000;
                        content: "•";
                        font-family: 'SegoeMDL2';
                        font-size: 15px;
                        margin-right: 10px;
                        margin-left: 10px;
                    }

                    li{
                        font-weight: 500;
                    }

                    a{
                        color: #006bbe;
                    }

                    a:hover{
                        text-decoration-line: underline;
                    }
                }       
            }

            img{
                padding: 5px;
                border-radius: 9px;
                display: none;
                border: 1px solid #ececf1;
                cursor: pointer;
                width: 38px;
                z-index: 3;
                background-color: white;
            }
            
            .line-title{
                border-top: 1px solid $primary;
                width: 60px;
                margin-left: 0px;
            }
            
            .title-art{
                color: $primary;
                font-size: 50px;
                padding-bottom: 20px;
                font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
                margin: 5px;
                font-weight: 500;
                line-height: 1.2;
            }

            .text-below-title{
                font-size: 22px;
                font-weight: 500;
                color: #000000;
            }

            .second-title-col{
                padding-top: 16px;

                .published-day{
                    font-size: 12px;
                    color: #757575;
                }
            }
        }

        .body-row{
            margin-top: 40px;

            &.vertical-gif{
                display: none;
                justify-content: center;
                margin-top: 60px;
                margin-left: 35px;
                margin-right: 35px;
                
                img{
                    border-radius: 15px;
                }
            }

            &.horizontal-gif{
                display: flex;
                justify-content: center;
                margin-top: 60px;
                margin-left: 35px;
                margin-right: 35px;

                img{
                    border-radius: 15px;
                }
            }

            &.translate{
                display: flex;
                flex-direction: row;
                align-items: center;

                .steps-card{
                    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 4px;
                    border-radius: 10px;
                    padding: 12px;
                    //background-color: rgb(20, 10, 104);

                    .card-title{
                        margin-bottom: 15px;
                    }

                    ol{
                        list-style-type:decimal;
                        margin-top: 10px;

                        li {
                            margin-top: 10px;
                            margin-left: 20px; /* Adjust the left margin */
                            margin-right: 10px; /* Adjust the right margin */
                            //color: #eeeeee;
                        }
                    }
                }
            }

            &.api-reference{
                display: block;
            }

            .api-reference-methods{
                display: flex;
                flex-direction: row;
                align-items: stretch;

                &.with-border{
                    border-top: 1px solid #ececf1;
                }

                .param-table{
                    margin-top: 0.5em;

                    .param-row{
                        display: flex;
                        flex-direction: column;
                        border-top: 1px solid #ececf1;
                        padding: 1em 0;

                        .param-row-header{
                            align-items: center;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: flex-start;
                            line-height: 20px;

                            .param-name{
                                color: black;
                            }

                            .param-type{
                                color: #8e8ea0;
                                font-size: 13px;
                                margin-left: 10px;
                            }

                            .param-required{
                                color: #ef4146;
                                font-size: 13px;
                                margin-left: 10px;
                            }
                        }

                        .param-row-body{
                            margin-top: 0.6em;

                            p{
                                font-size: 14px;
                            }

                            .object-param-table{
                                border: 1px solid #ececf1;
                                border-radius: 4px;
                                margin-top: 1em;

                                .object-param-row{
                                    padding: 12px;
                                }
                            }
                        }
                    }
                }
            }

            .column-paragraph{
                margin-bottom: 15px;

                &.reference-margin{
                    margin-right: 225px;
                }

                &.api-reference{
                    margin-top: 20px;
                    padding-top: 40px;
                    border-top: 1px solid #ececf1;

                    &.reference-margin{
                        margin-right: 225px;

                        &.no-border{
                            border-top: 0px solid #ececf1;
                            padding-top: 20px;
                        }
                    }

                    &.no-border{
                        border-top: 0px solid #ececf1;
                        padding-top: 20px;

                        &.left{
                            flex: 0 1 700px;
                            width: 42%;
                        }

                        &.right{
                            flex: 0 1 500px;
                            width: 56%;
                        }
                    }
                }

                &.with-image{
                    margin-bottom: 40px;
                }

                &.with-gif{
                    margin-bottom: 20px;

                    &.smart-gif{
                        margin-bottom: 40px;
                    }

                    &.translator-gif{
                        margin-bottom: 40px;

                        .paragraph-row{
                            .first-column{
                                flex: 0 0 59%;
                            }

                            .second-column{
                                flex: 0 0 41%;
                            }
                        }
                    }
                }

                &.after-cpv-description{
                    margin-bottom: 40px;

                    .line-title{
                        width: 85px;
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }
                }

                &.with-table{
                    width: 100%;
                    overflow-x: auto;
                }

                &.before-keywords{
                    margin-bottom: 40px;
                }

                &.before-cpv-image{
                    margin-top: 5px;
                    margin-bottom: 5px;
                }

                &.before-np-image{
                    margin-bottom: 0px;
                }

                .cpv-table {
                    width: auto;
                    max-width: 100%;
                    margin-bottom: 35px;
                    border: 1px solid #e2e0e0;
                    margin-right: auto;
                    margin-left: auto;
                  
                    .tr-head{
                        background-color: $primary;
                    }
                  
                    .thead-td-table{
                        padding: 10px 45px 10px 10px;
                    }
                  
                    .tbody-td-table{
                        padding: 10px 45px 10px 10px;
                    }
                    
                    .thead-text{
                        font-weight: 600;
                        font-size: 17px;
                        color: white;
                        line-height: 30px;
                    }
                    
                    .tbody-text{
                        font-size: 15.5px;
                        color: #000000;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                
                a{
                    color: $secondary;
                    font-weight: 700;
                    cursor: pointer;
                }

                .code-sample{
                    margin: 24px 0;
                    background-color: #202123;
                    border-radius: 5px;

                    .code-sample-header{
                        background-color: #353740;
                        padding: 0px 12px;
                        color: #c5c5d2;
                        border-radius: 3px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;


                        h5{
                            flex: 0 1 auto;
                            font-size: 14px;
                            color: #c5c5d2;
                            font-weight: 400;
                            padding: 10px 0px 9px 0px;
                            margin-top: 3px;
                        }

                        button{
                            background-color: #353740;
                            color: #c5c5d2;
                            padding: 6px 10px;
                            margin: 4px 0px;
                        }

                        button:hover{
                            background-color: #565869;
                        }

                        button:active{
                            background-color: #636577;
                        }
                    }

                    .code-sample-body{
                        position: relative;

                        button{
                            background-color: #353740;
                            padding: 9px 13px 12px 13px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            opacity: 1;
                            position: absolute;
                            right: 16px;
                            top: 16px;
                            font-size: 16px;
                            border-radius: 3px;

                            #text{
                                color: blue;    
                            }

                            i{
                                color: white;
                                margin: 0;
                            }

                            i.check{
                                color: #00a67d;
                            }
                        }

                        button.simple{
                            top: 8px;
                        }

                        button:hover{
                            background-color: #565869;
                        }

                        button:active{
                            background-color: #636577;
                        }

                        .code-sample-pre{
                            padding: 12px 16px;
                            min-height: 44px;
                            margin: 0;
                            font-size: 15px;
                            line-height: 24px;
                            overflow-x: auto;

                            .language-json{
                                white-space: pre;
                                font-family: var(--monospace);
                                display: flex;
                                flex-direction: row;

                                code{
                                    white-space: pre;
                                    float: left;
                                    padding-right: 16px;
                                    display: flex;
                                    flex-direction: column;

                                    .react-syntax-highlighter-line-number{
                                        color: #6e6e80;
                                        padding-bottom: 2px;
                                    }
                                }

                                .json-code{
                                    display: flex;
                                    flex-direction: column;

                                    span{
                                        color: white;
                                    }

                                    p{
                                        font-size: 14px;
                                    }

                                    .string{
                                        color: #00a67d;
                                    }

                                    .number{
                                        color: #df3079;
                                    }

                                    .boolean{
                                        color: #2e95d3;
                                    }
                                }
                            }
                        }

                        .code-sample-pre::-webkit-scrollbar-thumb {
                            background-color: grey;
                        }
                    }
                }

                .sectors-row{
                    margin-top: 40px;
                    margin-bottom: 40px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;

                    .sector-div{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-around;
                        align-items: center;
                        width: 310px;
                        padding: 10px;
                        box-shadow: 0 4px 8px 0px #00000033;
                        border-radius: 10px;
                        align-items: center;
                        margin: 15px;
                        cursor: default;

                        h1{
                            font-size: 16px;
                            line-height: 20px;
                            color: $secondary;
                            text-align: center;
                        }

                        img{
                            text-align: center;
                            width: 55px;
                            height: auto;
                        }
                    }

                    .sector-div:hover{
                        transform: scale(1.02);
                        transition: 0.8s;
                        z-index: 2;
                    }
                }

                .cards-row{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    margin-bottom: 40px;

                    .card-div{
                        display: flex;
                        flex-direction: row;
                        width: 500px;
                        border: 1px solid #4c4cc5;
                        border-top: 4px solid #3a3abf;
                        box-shadow: 0 10px 20px 5px #f1f1f1;
                        border-radius: 10px;
                        padding: 25px;
                        margin: 20px;

                        .card-div-content{
                            display: flex;
                            flex-direction: column;

                            .card-div-content-header{
                                margin-bottom: 15px;

                                h1{
                                    font-size: 20px;
                                    color: $primary;
                                }
                            }

                            .card-div-content-body{
                                p{
                                    font-size: 17px;
                                    color: #676767;
                                }
                            }
                        }
                    }
                }

                .list{
                    list-style-type: disc;
                    margin-top: 25px;
                    margin-bottom: 30px;

                    li {
                        margin-left: 30px; /* Adjust the left margin */
                        margin-right: 10px; /* Adjust the right margin */
                        color: #eeeeee;
                    }
                }
            }

            .art-subtitle{
                font-size: 18px;
                font-weight: 500;
                color: #000000;
                margin-bottom: 15px;

                &.key-words{
                    color: #6A6972;
                }

                &.api-reference{
                    font-size: 20px;

                    &.body{
                        margin-top: 2rem;
                    }
                }
            }

            .art-paragraph{
                margin-bottom: 20px;

                span{
                    font-weight: 500;
                    color: #000000;
                }

                .link-to{
                    color: #337ab7;
                    cursor: pointer;
                }

                &.cpv-description{
                    font-size: 12px;
                    margin-bottom: 0px;
                }

                .key{
                    background: #edeef180;
                    border: 1px solid #aeaebc33;
                    border-radius: 4px;
                    font-weight: 400;
                    margin: 0px 2px;
                    font-size: 13px;
                    padding: 0px 4px;
                }
            }

            .art-paragraph-below{
                margin-bottom: 20px;

                span{
                    color: #337ab7;
                    cursor: pointer;
                }
            }

            .paragraph-row{
                flex-direction: row;

                .art-subtitle{
                    font-size: 18px;
                    font-weight: 500;
                    color: #000000;
                    margin-bottom: 15px;

                    &.key-words{
                        color: #6A6972;
                    }
                }

                .art-paragraph{
                    margin-bottom: 20px;

                    &.last{
                        margin-bottom: 0px;
                    }

                    span{
                        font-weight: 500;
                        color: #000000;

                        
                    }
                }

                .first-column{
                    flex: 0 0 40%;
                    justify-content: center;
                    margin-top: 15px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    &.with-out-margin-top{
                        margin-top: 0px;
                    }

                    &.cpv-image{
                        flex: 0 0 38%;
                        margin-top: 0px;
                        justify-content: space-evenly;
                    }

                    &.second-img{
                        flex: 0 0 60%;
                    }

                    &.smart-filters{
                        flex: 0 0 35%;
                    }

                    &.smart-filters-gif{
                        flex: 0 0 35%;
                        display: flex;
                        flex-direction: column;
                    }

                    &.np-gif{
                        margin-top: 0px;
                        justify-content: flex-start;
                    }
                }

                .second-column{
                    flex: 0 0 60%;
                    text-align: center;

                    &.second-img{
                        flex: 0 0 40%;
                        
                        &.smart-img{
                            margin-top: 60px;
                        }

                        .image{
                            max-width: 95%;
                        }
                    }

                    &.smart-filters{
                        flex: 0 0 65%;
                    }

                    &.smart-filters-gif{
                        flex: 0 0 65%;
                    }
                    
                    &.smart-filters-one-image{
                        flex: 0 0 100%;

                        .image{
                            max-width: 90%;
                        }
                    }

                    &.cpv-image{
                        flex: 0 0 62%;
                    }

                    .image{
                        cursor: zoom-in;
                        max-width: 95%;
                        max-height: 100%;
                        box-shadow: 0 0 20px 0px #f1f1f1;
                        border: 1px solid #d1d1d1;
                        border-radius: calc(1.25rem - 1px);
                    }

                    .image:hover{
                        max-width: 100%;
                        max-height: 100%;
                        box-shadow: 0 0 20px 0px #f1f1f1;
                        border: 1px solid #d1d1d1;
                        border-radius: calc(1.25rem - 1px);
                    }
                }
            }
        }
    }

    .share-and-more-arts-container{
        display: flex;
        flex-direction: row;
        margin-top: 100px;
        padding-left: 0px;
        padding-right: 0px;
        
        .more-arts-container{
            flex: 0 0 75%;
            padding: 30px;
            background: white;
            margin-right: 1%;
            box-shadow: 0px 0px 20px 9px #ececec;
            border-radius: 10px;

            .body-row{    
                margin-top: 0px;

                .column-paragraph{
                    margin-bottom: 0px;
                }
    
                .more-arts-title{
                    font-size: 22px;
                    font-weight: 500;
                    color: #000000;
                    margin-bottom: 15px;
                }

                ul{
                    list-style-type: disc;
                    padding-inline-start: 20px;
                }
        
                .list-margin{
                    margin-bottom: 0px;

                    .summary-item{
                        font-size: 15px;
                        color: #337ab7;
                        cursor: pointer;
                        line-height: 25px;
                        margin-bottom: 5px;
                    }
                }
            }
        }

        .share-container{
            flex: 0 0 20%;
            padding: 30px 20px 30px 20px;
            background: #ffffff;
            box-shadow: 0px 0px 20px 9px #ececec;
            border-radius: 10px;

            .body-row{
                flex-direction: column;
                margin-top: 0px;

                .share-title{
                    font-size: 22px;
                    font-weight: 500;
                    color: #000000;
                    margin-bottom: 20px;
                }

                .column-share-buttons{
                    display: flex;
                    justify-content: center;
                    margin-top: 30px;

                    svg{
                        border-radius: 5px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1700px) {
    .art-section{
        .art-container{
            &.api-reference{
                padding-right: 150px;
                padding-left: 150px;
            }

            &.article{
                padding-right: 75px;
                padding-left: 75px;
            }

            .body-row{
                .column-paragraph{
                    &.reference-margin{
                        margin-right: 225px;
                    }
    
                    &.api-reference{
                        &.reference-margin{
                            margin-right: 225px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1400px) {
    .art-section{
        .art-container{
            &.api-reference{
                padding-right: 130px;
                padding-left: 130px;
            }

            &.article{
                padding-right: 30px;
                padding-left: 30px;
                margin-left: 145px;
            }

            .body-row{
                .column-paragraph{
                    &.reference-margin{
                        margin-right: 150px;
                    }
    
                    &.api-reference{
                        &.reference-margin{
                            margin-right: 150px;
                        }
                    }
                }
            }
        }

        .api-reference-body .api-reference-nav{ 
            &.articles{
                width: 145px;

                h2{
                    padding-right: 15px;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .art-section{
        .art-container{
            &.api-reference{
                padding-right: 75px;
                padding-left: 75px;
            }

            &.article{
                padding-right: 50px;
                padding-left: 50px;
                margin-left: 0px;
            }

            .body-row{
                &.translate{
                    flex-direction: column;
                }

                .column-paragraph{
                    &.reference-margin{
                        margin-right: 100px;
                    }
    
                    &.api-reference{
                        &.reference-margin{
                            margin-right: 100px;
                        }
                    }
                }
            }

            .title-row{
                &.navigation{
                    flex-direction: row;
                    justify-content: space-between;
                    
                }

                img{
                    display: block;
                }
            }
        }

        .api-reference-body .api-reference-nav{ 
            &.articles{
                display: none;
            }
        }
    }
}

@media (max-width: 991px) {
    .art-section{
        .art-container{

            &.api-reference{
                padding-right: 50px;
                padding-left: 50px;
                margin-left: 195px;
            }

            &.article{
                padding-right: 30px;
                padding-left: 30px;
            }

            .body-row{

                &.vertical-gif{
                    display: flex;
                    margin-left: 5px;
                    margin-right: 5px;
                }

                &.horizontal-gif{
                    display: none;
                }

                .column-paragraph{ 
                    &.with-gif{
                        margin-bottom: 40px;
                    }

                    &.reference-margin{
                        margin-right: 50px;
                    }

                    &.api-reference{
                        &.reference-margin{
                            margin-right: 50px;
                        }
                    }
                }

                .paragraph-row{
                    flex-direction: column;

                    .art-paragraph{
                        &.last{
                            margin-bottom: 20px;
                        }
                    }
                    
                    .first-column{
                        flex: 0 0 auto;
                        margin-top: 15px;
    
                        &.second-img{
                            flex: 0 0 auto;
                        }
                    }
    
                    .second-column{
                        flex: 0 0 auto;
                        text-align: center;
    
                        &.second-img{
                            flex: 0 0 auto;
    
                            &.smart-img{
                                margin-top: 0px;
                            }

                            .image{
                                max-width: 90%;
                            }
                        }
    
                        .image{
                            margin-top: 10px;
                            max-width: 90%;
                        }
                    }
                }
            }
        }

        .api-reference-body .api-reference-nav{
            width: 195px;
        }

        .share-and-more-arts-container{
            flex-direction: column;

            .share-container{
                margin-top: 20px;
                max-width: 300px;

                .body-row{
                    .column-share-buttons{
                        margin-top: 0px;
                    }
                }
            }
        }
    }
}

@media (max-width: 900px) {
    .art-section{
        .art-container{
            &.api-reference{
                margin-left: 50px;
                margin-right: 50px;
                margin-top: 122px;
                padding-top: 30px;
                margin-bottom: 40px;
            }
        }

        .api-reference-body .api-reference-nav{
            display: none;
        }
    }
}

@media (max-width: 800px) {
    .art-section{
        .art-container{
            .body-row{
                .api-reference-methods{
                    flex-direction: column;
                }

                .column-paragraph{
                    &.reference-margin{
                        margin-right: 0px;
                    }

                    &.api-reference{
                        margin-top: 0px;

                        &.reference-margin{
                            margin-right: 0px;
                        }

                        &.no-border{
                            &.left{
                                flex: 0 1 auto;
                                width: 100%;
                            }
    
                            &.right{
                                flex: 0 1 auto;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .art-section .art-container .body-row .column-paragraph.api-reference{

    }
}

@media (max-width: 767px) {
    .art-section{

        &.release-notes{
            padding: 114px 10px 64px 10px;
        }

        .art-container{
            &.api-reference{
                margin-left: 15px;
                margin-right: 15px;
                padding-left: 28px;
                padding-right: 28px;
            }

            .title-row{
                &.navigation{
                    align-items: flex-end;
                }

                .title-art{
                    font-size: 42px;
                }

                .text-below-title{
                    font-size: 21px;
                }

                .title-col {
                    flex-direction: column-reverse;
                    justify-content: center;

                    .navigation{
                        display: none;
                    }
                    
                }
            }
        }
    }
}

/*************END-BLOG*****************/

/*********SATISFACTION-SURVEY**********/

.satisfaction-survey-div{
    display: flex;
    flex-flow: column;
    height: 100%;
    background: linear-gradient(90deg, rgb(25, 20, 41) 3%, rgb(20, 10, 30) 15%, rgb(20, 10, 110) 85%, rgb(40, 40, 114) 99%);

    .art-section{
        flex: 1 1 auto;
        padding: 114px 32px 64px 32px;
        background: transparent;

        .art-container{
            background: transparent;
            padding: 30px;

            .title-row{
                flex-direction: column;

                .line-title{
                    border-top: 1px solid #3A3ABF;
                    width: 60px;
                    margin-left: 0px;
                }

                .title{
                    color: $secondary;
                    font-size: 50px;
                    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
                    margin: 5px 5px 5px 0px;
                    font-weight: 500;
                    line-height: 1.2;
                }

                .title-col{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .navigation{
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        padding-inline-start: 5px;
                        font-size: 15px;
                        align-items: center;

                        li{
                            font-weight: 500; 
                            color: #cccccc;;
                        }

                        li + li:before {
                            color: #cccccc;;
                            content: "•";
                            font-family: 'SegoeMDL2';
                            font-size: 15px;
                            margin-right: 10px;
                            margin-left: 10px;
                        }

                        a{
                            color: #cccccc;;
                        }
                    }
                }

                .second-title-col{
                    padding-top: 8px;

                    .published-day{
                        font-size: 12px;
                        color: #cccccc;
                    }
                }
            }

            .body-row{
                margin-top: 40px;
                flex-direction: column;

                .column-paragraph{
                    margin-bottom: 15px;
                    display: flex;
                    flex-direction: column;

                    &.button{
                        display: flex;
                        flex-direction: row-reverse;
                    }

                    .input{
                        height: auto;
                        min-height: 100px;
                        max-height: 160px;
                        width: 100%;
                        background-color: #e9e9e9;
                        border: 1px solid #E2E2E2;
                        border-color: #E5AC69;
                        display: block;
                        padding: 0.375rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #495057;
                        background-clip: padding-box;
                        border-radius: 0.25rem;
                        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                        margin: 5px 0px;
                    }

                    .option{
                        position: relative;
                        display: inline-flex;
                        align-items: center;
                        padding-left: 0;
                        margin-right: 0.75rem;
                        margin-bottom: 5px;

                        &.last-option{
                            margin-bottom: 15px;
                        }
                    }

                    .check-input{
                        position: static;
                        margin-top: 0;
                        margin-right: 0.3125rem;
                        margin-left: 0;
                    }

                    .label{
                        font-size: 14px;
                        font-weight: 500;
                        margin-bottom: 0px;
                        color: #E5AC69;
                    }
                }

                .art-subtitle{
                    font-weight: 500;
                    color: #dddddd;
                    margin-bottom: 15px;
                    font-size: 16px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .satisfaction-survey-div{
        .art-section{
            padding-left: 10px;
            padding-right: 10px;

            .art-container{
                .title-row{
                    .title-col {
                        .navigation{
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

/******END-SATISFACTION-SURVEY*********/

/*************FOOTER*******************/

@media (max-width: 992px){

    .footer{
        .col-lg-2 {
            text-align: center;
            padding-bottom: 20px;
        }
    }
}

/*************END-FOOTER****************/ 

a.pricing{
    display: block;
}

label.labelIncompleteData{
    font-size: 0.9rem !important;
    color: $button-error-hover !important;
    margin-top: 10px !important;
    margin-bottom: 0px !important;
}

@media only screen and (max-width: 767px) {
    label.labelIncompleteData{
        margin: 0px !important;
    }
}

@media (max-width: 992px){
    .form-control {
        margin-top: 8px;
        margin-bottom: 8px;
    }
}

.col-lg-2{
    &.footer{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        img{
            margin-bottom: 10px;
        }

        .mp{
            max-width: 80px;
        }
    }
}

.col-lg-9 {

    &.footer {

        flex: 0 0 80%;
        max-width: 80%;

        .nav {
            justify-content: space-between;
        }
    }
}

@media (max-width: 767px) {
    .col-lg-2{
        &.footer{    
            padding-bottom: 10px;
        }
    }
}

@media (max-width: 992px) {

    .col-lg-2{
        &.footer{    
            .mp{
                margin-bottom: 0px;
            }
        }
    }
}

@media (min-width: 992px) {

    .col-lg-2 {
        &.footer {

            flex: 0 0 18.666667%;
            max-width: 18.666667%;

            
        }
    }

    .col-lg-9 {

        &.footer {

            flex: 0 0 80%;
            max-width: 80%;
        }
    }
}

/***************SIGN-UP****************/

.div-user-name{
    display: flex;
    flex-direction: row;
    align-items: baseline;

    .user-icon{
        margin-left: 10px;
        font-size: 18px;
        align-items: center;

        &.success{
            color: $success;
        }

        &.failed{
            color: $error;
        }
    }
}

#sign-up{
    background: linear-gradient(90deg, rgb(25, 20, 41) 3%, rgb(20, 10, 30) 15%, rgb(20, 10, 110) 85%, rgb(40, 40, 114) 99%);
    margin-top: 82px;
    padding-top: 40px;
    padding-bottom: 40px;
    height: auto;

    .sign-up-form{
        margin-top: 20px;
        margin-bottom: 20px;
        background: transparent;
        background: #10066d;
        background: linear-gradient(90deg, rgb(40, 40, 114) 10%,  rgb(20, 10, 110), rgb(40, 40, 114) 100%);
        padding-top: 48px;
        padding-bottom: 48px;
        border-radius: 24px;
        box-shadow: 0px 0px 15px 10px #cecece33;
        box-shadow: 0px 0px 15px 10px #00000033;

        label{
            color: #dddddd;
        }

        p{
            color: #dddddd;
        }

        .title{
            color: $secondary;
            margin-bottom: 16px;
        }

        .form-check-label{
            cursor: pointer;
            margin-left: 2px;
            font-size: 1rem !important;

            a{
                display: inline;
            }

            a:last-child{
                font-weight: 600;
            }

            a:hover{
                color: $secondary;
            }
        }
    }
}

@media (max-width: 767px) {
    #sign-up{
        .sign-up-form {
            padding: 30px;
            border-radius: 10px;
        }
    }
}

@media (max-width: 600px) {
    #sign-up{
        .sign-up-form{
            margin-right: 15px;
            margin-left: 15px;
            width: auto;
        }
    }
}

/*************END-SIGN-UP***************/

/***********VERTICAL-TIMELINE***********/

.col-lg-12.vertical-timeline-col{
    margin-top: 50px;
    padding-bottom: 65px;

    &.video{
        margin-top: 40px;
        padding-bottom: 30px;
    }

    .more{
        display: initial;
    }
}

.vertical-timeline::before{
    background: transparent !important;
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in{
    animation: cd-bounce-2-inverse 1.4s !important;
}

.vertical-timeline--animate .vertical-timeline-element-content.bounce-in{
    animation: cd-bounce-2-inverse 1.4s !important;
}

.vertical-timeline-element{
    a{
        display: initial;
    }
    
    .vertical-timeline-element-icon.bounce-in{
        background-color: $primary;
        color: white;
    
        .file{
            font-size: 38px;
            margin-top: 10px;
            margin-left: 8px;
        }

        i{
            font-size: 50px;
            margin-top: 5px;
            margin-left: 0.5px;
        }

        .right{
            margin-left: 0px;
        }
    }

    .vertical-timeline-element-content.bounce-in{
        box-shadow: none;
        border-top: 6px solid #2d1e5f;
        border-radius: 8px;
    
        .vertical-timeline-element-title{
            font-size: 26px;
            color: $primary;
            margin-bottom: 7px;
        }

        .vertical-timeline-element-subtitle{
            color: $secondary;
            font-size: 15px;
        }

        .paragraph{
            color: #fff;
            margin-top: 10px;
            margin-bottom: 5px;

            .bold{
                font-weight: 700;
                color: black;
            }

            .high-light{
                font-weight: 700;
                color: $secondary;
            }
        }

        .firstone{
            margin-bottom: 3px;
            word-break: break-word;
        }

        ul{
            list-style-type: disc;
            padding-inline-start: 20px;
            color: #fff;

            li{
                margin-bottom: 3px;
            }

            li:last-child{
                margin-bottom: 0px;
            }
        }

        ::marker{
            color: #fff;
        }

        .flag{
            width: 25px;
            margin-top: -2px;
            margin-left: 2px;
            border: 1px solid black;
        }

        .vertical-timeline-element-content-arrow{
            border-right: 8px solid $secondary;
        }

        .vertical-timeline-element-date{
            color: white;
            opacity: .9;
        }
    }

    &.vertical-timeline-element--no-children{
        .vertical-timeline-element-content.bounce-in{
            border-top: none;
            background: transparent;
        }
    }

    &.art{
        .vertical-timeline-element-icon.bounce-in{
            box-shadow: none;
            outline: 3px solid $primary;
            outline-offset: 5px;
        }

        .vertical-timeline-element-content.bounce-in{
            border-top: 4px solid $primary;
            box-shadow: 0px 6px 10px #ddd;
        
            .vertical-timeline-element-content-arrow{
                border-right: 8px solid $primary;
            }

            .vertical-timeline-element-date{
                color: #6A6972;
                opacity: .9;
            }

            ul{
                color: #6A6972;
            }

            ::marker{
                color: #6A6972;
            }

            .paragraph{
                color: #6A6972;

                .high-light{
                    color: $primary;
                }
            }
        }
    }
}

@media (max-width: 550px) {
    .col-lg-12.vertical-timeline-col{
        margin-top: 10px;
        padding-bottom: 65px;
    
        &.video{
            margin-top: 40px;
            padding-bottom: 0px;
        }
    
        .more{
            display: initial;
        }
    }
}

@media only screen and (min-width: 100px){
    .vertical-timeline.vertical-timeline--two-columns {
        width: 90%;
    }

    .vertical-timeline--two-columns .vertical-timeline-element-icon {
        width: 40px;
        height: 40px;
        margin-left: -5px;
        margin-top: 10px;
    }

    .vertical-timeline-element .vertical-timeline-element-icon.bounce-in{ 
        .file {
            font-size: 24px;
            margin-top: 7px;
            margin-left: 6px;
        }

        i{
            font-size: 32px;
            margin-top: 4px;
            margin-left: 0.5px;
        }

        .right{
            margin-left: 1px;
        }
    }

    .vertical-timeline--two-columns .vertical-timeline-element-content {
        padding: 1.5em;
        background-color: #191429;
    }

    .vertical-timeline-element-content p{
        font-size: 1rem !important;
    }

    .vertical-timeline-element {
        .vertical-timeline-element-content.bounce-in{
            .vertical-timeline-element-title {
                font-size: 21.5px;
            }
    
            .vertical-timeline-element-date {
                padding: 0.8em 0 0 0;
                position: absolute;
                width: 100%;
                left: -78px;
                top: 50px;
            }
    
            li{
                font-size: 13.5px;
            }
        }

        &.art{
            .vertical-timeline-element-content.bounce-in{
                background-color: transparent;
            }
        }
    }

    .vertical-timeline-element.vertical-timeline-element--no-children{
        .vertical-timeline-element-icon.bounce-in {
            left: 50%;
            margin-left: 0px;
            margin-top: 20px;
        }

        .vertical-timeline-element-content.bounce-in{
            .vertical-timeline-element-date {
                padding: 0.8em 0 0 0;
                position: absolute;
                width: fit-content;
                left: calc(62% - 100px);
                top: 65px;
                font-size: 13px;
            }
        }
    }
}

@media only screen and (min-width: 510px){
    .vertical-timeline.vertical-timeline--two-columns {
        width: 75%;
    }

    .vertical-timeline-element .vertical-timeline-element-content.bounce-in{
        .vertical-timeline-element-title {
            font-size: 26px !important;
        }

        li{
            font-size: 1rem !important;
        }

        .vertical-timeline-element-date {
            padding: 0.8em 0 0 0;
            position: absolute;
            width: 100%;
            left: -97px;
            top: 60px;
            font-size: 13px;
        }
    } 

    .vertical-timeline--two-columns .vertical-timeline-element-icon {
        width: 50px;
        height: 50px;
        margin-left: -15px;
        margin-top: 10px;
    }

    .vertical-timeline-element .vertical-timeline-element-icon.bounce-in{ 
        .file {
            font-size: 32px;
            margin-top: 8px;
            margin-left: 7px;
        }

        i{
            font-size: 42px;
            margin-top: 4px;
            margin-left: 0.5px;
        }

        .right{
            margin-left: 0px;
        }
    }

    .vertical-timeline-element.vertical-timeline-element--no-children{
        .vertical-timeline-element-content.bounce-in{
            .vertical-timeline-element-date {
                padding: 0.8em 0 0 0;
                position: absolute;
                width: fit-content;
                left: calc(58% - 90px);
                top: 85px;
                font-size: 13px;
            }
        }
    }
}

@media only screen and (min-width: 768px){
    .vertical-timeline.vertical-timeline--two-columns {
        width: 70%;
    }

    .vertical-timeline--two-columns .vertical-timeline-element-icon {
        width: 60px;
        height: 60px;
        margin-left: -30px;
        margin-top: 0px;
    }

    .vertical-timeline-element .vertical-timeline-element-icon.bounce-in{ 
        i {
            font-size: 50px;
            margin-top: 5px;
            margin-left: 0.5px;
        }

        .file {
            font-size: 38px;
            margin-top: 10px;
            margin-left: 9px;
        }

        .right{
            margin-left: 0.5px;
        }
    }
}

@media only screen and (min-width: 992px){
    .vertical-timeline.vertical-timeline--two-columns {
        width: 90%;
    }

    .vertical-timeline-element .vertical-timeline-element-content.bounce-in .vertical-timeline-element-date{
        color: white;
    }

    .vertical-timeline-element:first-child {
        margin-top: 0;
    }

    .vertical-timeline-element {
        margin: 4em 0;
    }

    .vertical-timeline--two-columns .vertical-timeline-element-icon {
        width: 60px;
        height: 60px;
        left: 50%;
        margin-left: -30px;
    }

    .vertical-timeline--two-columns .vertical-timeline-element-content {
        margin-left: 0;
        padding: 1.5em;
        width: 44%;
    }

    .vertical-timeline--two-columns .vertical-timeline-element-content-arrow {
        top: 24px;
        left: 100%;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    .vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
        position: absolute;
        width: 100%;
        left: 124%;
        top: 6px;
        font-size: 16px;
        font-size: 1rem;
    }

    .vertical-timeline.vertical-timeline--two-columns:before {
        left: 50%;
        margin-left: -2px;
    }

    .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content {
        float: right;
    }

    .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content .vertical-timeline-element-date {
        right: 126%;
        left: auto;
        text-align: right;
    }

    .vertical-timeline-element .vertical-timeline-element-content.bounce-in{
        .vertical-timeline-element-date {
            padding: 0.8em 0 0 0;
            position: absolute;
            width: 100%;
            left: 126%;
            top: 6px;
            font-size: 13px;
        }
    }

    .vertical-timeline-element.vertical-timeline-element--no-children{
        margin-top: 0px;
        .vertical-timeline-element-content.bounce-in{
            .vertical-timeline-element-date {
                padding: 0.8em 0 0 0;
                position: absolute;
                width: 50%;
                left: 99% !important;
                top: 70px;
                font-size: 15px;
            }
        }

        .vertical-timeline-element-icon.bounce-in{
            margin-left: -30px;
            margin-top: 0px;
        }
    }

    .vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content-arrow, .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content-arrow {
        top: 24px;
        left: auto;
        right: 100%;
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    .vertical-timeline--two-columns .vertical-timeline-element-content-arrow {
        top: 24px;
        left: 100%;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

/*********END-VERTICAL-TIMELINE*********/

/**************FEATURES*************/

#features{
    overflow: hidden;
    background: linear-gradient(90deg, rgb(25, 20, 41) 3%, rgb(20, 10, 30) 15%, rgb(20, 10, 110) 85%, rgb(40, 40, 114) 99%);
    
    .container{
        max-width: 1750px;
    }
}

.features-carousel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    position: relative;
    min-height: 455px;
    margin-top: 100px;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.8s ease-in-out;

    &.visible{
        opacity: 1;
        transform: translateY(0);
    }

    .carousel-button {
        background: transparent;
        color: $primary;
        border: none;
        padding: 10px 10px;
        margin-left: 0px;
        margin-right: 75px;
        cursor: pointer;
        font-size: 45px;
        z-index: 2;
        
        &.next{
            margin-left: 75px;
            margin-right: 0px;
        }

        i{
            margin: 0 !important;
        }
    }

    .carousel-button:hover {
        background: transparent;
        color: $primary;
    }

    .carousel-button:focus {
        background: transparent;
        color: $primary;
    }

    .carousel-row{
        opacity: 0;
        position: absolute;
        left: 100%;
        transition: opacity 0.5s, left 1s;
        width: 100%;

        &.active{
            opacity: 1 !important;
            left: 0;
            position: relative;
        }

        .feature-row{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 0px;
            margin-right: 0px;

            .feature-content{
                width: 50%;

                .feature-content-header{
                    margin: 0px 50px;

                    h3{
                        margin-bottom: 15px;
                        color: $secondary;
                    }
                }

                .feature-content-body{
                    margin: 0px 50px;

                    p{
                        color: #fff;
                    }

                    a{
                        color: $primary;
                        line-height: 26px;
                        font-size: 16px;
                        font-weight: 500;
                    }
        
                    a:hover{
                        color: $secondary;
                    }

                    .button-container{
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        margin-top: 15px;
                        margin-right: 10px;

                        button{
                            margin-top: 12px;
                            margin-bottom: 12px;
                            border: 2px solid #3A3ABF;
                            border-radius: 4px;
                            color: white;
                            background-color: #3A3ABF;
                            font-size: 16px;
                            font-weight: 600;
                            padding: 7px 12px;
                        }
                    }
                }
            }

            .feature-image{
                text-align: center;
                width: 50%;
            }
        }
    }
}

@media (max-width: 1200px) {
    .features-carousel{

        .carousel-button {
            margin-right: 20px;
            
            &.next{
                margin-left: 20px;
            }
        }

        .carousel-row{
            .feature-row{
                .feature-content{
                    width: 40%;

                    .feature-content-header{
                        margin: 0px 25px;
                    }
    
                    .feature-content-body{
                        margin: 0px 25px;
                    }
                }

                .feature-image{
                    width: 60%;
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .features-carousel{
        .carousel-button {
            margin-right: 20px;
            
            &.next{
                margin-left: 20px;
            }
        }

        .carousel-row{
            .feature-row{
                flex-direction: column;

                &.reverse{
                    flex-direction: column-reverse;
                }

                .feature-content{
                    width: 100%;

                    .feature-content-body{
                        .button-container{
                            margin-bottom: 10px;
                        }
                    }
                }

                .feature-image{
                    margin-top: 30px;
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 650px) {
    .features-carousel{
        min-height: 550px;
        margin-top: 60px;

        .carousel-button {
            position: absolute;
            margin: 0px;
            left: -45px;
            font-size: 38px;
            
            &.next{
                margin: 0px;
                left: auto;
                right: -45px;
            }
        }

        .carousel-row{
            .feature-row{
                flex-direction: column;

                &.reverse{
                    flex-direction: column-reverse;
                }

                .feature-content{
                    width: 100%;

                    .feature-content-header{
                        margin: 0 20px;
                    }

                    .feature-content-body{
                        margin: 0 20px;
                    }
                }

                .feature-image{
                    margin-top: 30px;
                    width: 100%;
                }
            }
        }
    }
}


/************END-FEATURES***********/

.title-row{
    margin-left: 0px;
    margin-right: 0px;
}

/***************PRICING***************/

#pricing{
    background: linear-gradient(90deg, rgb(25, 20, 41) 3%, rgb(20, 10, 30) 15%, rgb(20, 10, 110) 85%, rgb(40, 40, 114) 99%);
}

.switch-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 70px;
    margin-bottom: 50px;

    .switch-div{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        border: 2px solid $primary;
        padding: 20px;
        border-radius: 10px;
        width: 425px;

        .text{
            color: #fff;
            font-size: 26px;
            font-weight: 500;
            line-height: 40px;
            letter-spacing: 0.5px;

            &.active{
                color: $primary;
            }
        }

        .switch{
            label{
                margin-bottom: 0px;
            }
        }

        .label{
            background-color: #00bcd6;
            font-size: 14px;
            font-weight: 600;
            border-radius: 5px;
            color: white;
            margin-bottom: 0px;
            padding: 5px 10px;
        }

        .ui.toggle.checkbox{
            width: 70px;
            height: 40px;
        }

        .ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before {
            width: 70px;
            height: 40px;
        }

        .ui.toggle.checkbox label:after {
            width: 30px;
            height: 30px;
            top: 5px;
            left: 5px;
        }

        .ui.checkbox input:checked~label:after{
            left: 35px;
        }

        .ui.toggle.checkbox input:checked~label:before {
            background-color: $primary !important;
        }

        .ui.toggle.checkbox label:before{
            background-color: $primary;
        }
    }
}

.pricing-row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .plan-container{
        flex: 0 1 410px;
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (max-width: 992px) {
    .container{
        &.pricing{
            max-width: 850px;
        }
    }
}

@media (max-width: 860px) {
    .pricing-row{
        flex-direction: column-reverse;
        align-items: center;

        .plan-container{
            flex: 0 1 auto;
            width: 350px;
            margin-bottom: 20px;
        }
    }
}

@media (max-width: 445px) {
    .switch-row{
        .switch-div{
            padding: 10px 8px;

            .text{
                font-size: 22px;
                font-weight: 500;
                letter-spacing: 0.4px;
            }

    
            .label{
                font-size: 13px;
            }
    
            .ui.toggle.checkbox{
                width: 55px;
                height: 30px;
            }
    
            .ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before {
                width: 55px;
                height: 30px;
            }
    
            .ui.toggle.checkbox label:after {
                width: 20px;
                height: 20px;
            }

            .ui.checkbox input:checked~label:after{
                left: 30px;
            }
        }
    }
}

/*************END-PRICING*************/

/*************EFFECTS****************/

@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
}

/***********END-EFFECTS**************/

/**************GENERAL******************/

.container{
    max-width: 1140px;
}

.general-row{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 60px;
    text-align: center;

    .title {
        font-size: 42px;
        padding-top: 10px;
        color: #3A3ABF;
        font-weight: 700;
        line-height: 42px; 
    }

    .subtitle{
        margin-top: 35px;
        text-align: center;
        padding-left: 150px;
        padding-right: 150px;
        color: #fff;
    }
}

@media (max-width: 1200px) {
    .container{
        max-width: 960px;
    }
}

@media (max-width: 992px) {
    .container{
        max-width: 720px;
    }

    .general-row{
        .subtitle{
            padding-left: 90px;
            padding-right: 90px;
        }
    }
}

@media (max-width: 767px) {
    .container{
        max-width: 540px;
    }

    .general-row{
        .title{
            font-size: 36px;
        }

        .subtitle{
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

/**************END-GENERAL**************/

/*********EXPLORE-OUR-RESOURCES*********/

#exploreResources{
    background: linear-gradient(rgba(40, 40, 114, 0.8) 5%, transparent 5%), linear-gradient(90deg, rgb(25, 20, 41) 3%, rgb(20, 10, 30) 15%, rgb(20, 10, 110) 85%, rgb(40, 40, 114) 99%);

    .explore-resources-row{
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 100px;
        padding-bottom: 30px;

        .resource-card{
            flex: 0 1 50%;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 25px; 
            border-top: 4px solid #3a3abf;
            box-shadow: 0px 0px 20px 1px #d4d4d4;
            border-radius: 10px;
            margin-right: 30px;
            margin-left: 20px;
            opacity: 0;
            transform: translateX(-20px);
            transition: all 1.2s ease-in-out;

            &.visible{
                transform: translateX(0px);
                opacity: 1;
            }
            
            .resource-card-info{
                display: flex;
                flex-direction: column;
                width: 80%;

                .resource-card-header{
                    margin-bottom: 20px;

                    .resource-title{
                        color: $primary;
                    }

                    .resource-icon{
                        display: none;
                    }
                }
    
                .resource-card-body{
                    p{
                        color: #fff;
                    }

                    a{
                        margin-top: 5px;
                        color: $secondary;
                        font-weight: 500;
                        font-size: 16px;
                    }
    
                    a:hover{
                        color: $primary;
                    }
                }
            }

            .resource-card-image{
                width: 20%;

                .resource-icon{
                    max-width: 75px;
                    max-height: 75px;
                    margin-left: 15px;
                    margin-right: 15px;
                } 
            } 
        }

        .resource-card:last-child{
            margin-right: 20px;
            margin-left: 30px;
            transform: translateX(20px);

            &.visible{
                transform: translateX(0px);
                opacity: 1;
            }
        }
    }
}

@media (max-width: 992px) {
    #exploreResources{
        .explore-resources-row{
            flex-direction: column;
            align-items: center;
            .resource-card{
                max-width: 75%;
                margin-bottom: 70px;
            }
        }
    }
}

@media (max-width: 767px) {
    #exploreResources{
        .explore-resources-row{
            .resource-card{
                max-width: 100%;
            }
        }
    }
}

@media (max-width: 550px) {
    #exploreResources{
        .explore-resources-row{
            .resource-card{
                max-width: 100%;
                margin-left: 10px;
                margin-right: 10px;

                .resource-card-info{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
    
                    .resource-card-header{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
    
                        .resource-icon{
                            display: block;
                            margin-right: 15px;
                            max-width: 35px;
                            max-height: 35px;
                        }
                    }
                }

                .resource-card-image{
                    display: none;
                }
            }

            .resource-card:last-child{
                margin-left: 8px;
                margin-right: 8px;
                margin-bottom: 0px;
            }
        }
    }
}

/*******END-EXPLORE-OUR-RESOURCES*******/

/*****************FAQs******************/

#faqs{
    background: linear-gradient(90deg, rgb(25, 20, 41) 3%, rgb(20, 10, 30) 15%, rgb(20, 10, 110) 85%, rgb(40, 40, 114) 99%);
}

.accordion{
    .card{
        background-color: transparent;

        .card-header{
            border-bottom: none;

            .toggleQuestion {
                display: flex;
                flex-direction: row;
                width: 100%;

                .divQuestionTitle {
                    color: #fff;
                    flex-grow: 1;
                    text-align: left;
                }

                .divIconTitle{
                    min-width: 20px;
                    margin-top: 1px;
                }
            }

            .toggleQuestion:focus{
                box-shadow: none;
                text-decoration: none;
            }

            .toggleQuestion:hover{
                background-color: #26266b !important;
            }
        }

        .collapse, .collapse.show, .collapsing{
            .card-body{
                margin-left: 20px;
                border-bottom: 1px solid rgba(0,0,0,.125);
                margin-bottom: 10px;
                color: #d1cfcf;
            }
        }
    }
}

@media (max-width: 767px) {
    .accordion{
        .toggleQuestion {    
            .divIconTitle{
                margin-left: 15px;
                //align-self: center;
            }
        }
    }
}

/***************END-FAQs****************/

/****************FOOTER*****************/

#footer{
    background-color: $new-primary-navbar;

    .container{
        .rowFooterContact{
            .footer{
                background-color: $new-primary-navbar;
            }
        }
    }
}

@media (max-width: 400px) {
    #footer{
        .menuFooterContact li img {
            margin-right: 5px;
        }

        .description-contact-footer.icons{
            font-size: 13.7px;
        }
    }
}

/**************END-FOOTER***************/

/***************DARK-MODE*****************/

.dark-mode-body{
    .blog-div{
        .art-section{
            &.api-reference{
                background: linear-gradient(90deg, rgb(25, 20, 41) 3%, rgb(20, 10, 30) 15%, rgb(20, 10, 110) 85%, rgb(40, 40, 114) 99%);

                .api-reference-body{
                    .api-reference-nav{
                        background-color: transparent;

                        h1{
                            color:#fff;
                        }

                        h2, h3, h4{
                            color:#cccccc;
                        }
                    }
                }

                .art-container{
                    background-color: transparent;

                    &.article{
                        .art-section{
                            &.articles{
                                background-color: transparent;
                            }
                        }
                    }

                    .title-row{    
                        .title-col {
                            .navigation{
                                li+li:before {
                                    color:#cccccc;
                                }

                                li{
                                    color:#cccccc
                                }

                                a{
                                    color:#cccccc
                                }
                            }
                        }

                        .title-art{
                            color: $secondary;
                        }

                        .text-below-title{
                            color: #dddddd;
                        }

                        .second-title-col{
                            .published-day{
                                color:#cccccc;
                            }
                        }

                        img{
                            border-color: #140a6e;
                            background-color: #cccccc;
                        }
                    }

                    .body-row{
                        
                        &.translate{       
                            .steps-card{
                                background-color: rgb(20, 10, 104);
            
                                .card-title{
                                    color: #cccccc !important;
                                }
            
                                ol{
                                    li {
                                        color: #eeeeee;
                                    }
                                }
                            }
                        }

                        .art-paragraph{
                            color:#cccccc;

                            span{
                                color: white;
                            }
                        }

                        .art-paragraph-below{
                            color:#cccccc;
                        }

                        .art-subtitle{
                            color: #eeeeee;
                        }

                        .paragraph-row{
                            .second-column{
                                .image{
                                    box-shadow: none;
                                    border: none;
                                }
                            }
                        }

                        .column-paragraph{
                            .sectors-row{
                                .sector-div{
                                    box-shadow: 0px 0px 10px 0px #00000033;
                                    background-color: midnightblue;

                                    h1{
                                        color: #eeeeee;
                                    }
                                }
                            }

                            .cards-row{
                                .card-div{
                                    box-shadow: 0px 0px 10px 0px #999999;
                                    border: 1px solid #4230e0;
                                    border-top: 4px solid #4230e0;
                                    border-color: rgb(40, 40, 155);
                                    
                                    .card-div-content{
                                        .card-div-content-header{
                                            h1{
                                                color: #eeeeee;
                                            }
                                        }

                                        .card-div-content-body{
                                            p{
                                                color:#cccccc;
                                            }
                                        }
                                    }
                                }
                            }

                            .cpv-table{
                                .tbody-text{
                                    color: #eeeeee;
                                }
                            }
                        }

                        .api-reference-methods{
                            .param-table{
                                .param-row{
                                    .param-row-header{
                                        .param-name{
                                            color: #eeeeee;
                                            font-weight: 600;
                                        }

                                        .param-type{
                                            color: #dddddd;
                                        }
                                    }

                                    .param-row-body{
                                        p{
                                            color: #cccccc;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .share-and-more-arts-container{
                    .more-arts-container{
                        box-shadow: 0px 0px 15px 10px #00000033;
                        background-color: transparent;

                        .body-row{
                            .more-arts-title{
                                color:#cccccc;
                            }

                            .list-margin{
                                .summary-item{
                                    font-weight: 500;
                                }
                            }
                        }
                    }

                    .share-container{
                        box-shadow: 0px 0px 15px 10px #00000033;
                        background-color: transparent;

                        .body-row{
                            .share-title{
                                color:#cccccc;
                            }
                        }
                    }
                }
            }
        }
    }

    .vertical-timeline-element{
        &.art{
            .vertical-timeline-element-icon.bounce-in{
                outline-offset: 0px;
                outline-color: white;
            }

            .vertical-timeline-element-content.bounce-in{
                .paragraph{
                    color: #cccccc;

                    .high-light{
                        color: $secondary;
                    }

                    .bold{
                        color: white;
                    }
                }

                ul{
                    color: #bbbbbb;
                }

                ::marker{
                    color: #cccccc;
                }

                .vertical-timeline-element-date{
                    color: #cccccc;
                }
            }
        }
    }

    .ReactModal__Content{
        background: transparent;
    }

    .modal{
        &.nav{
            background: linear-gradient(90deg, #140a1e 15%, #140a6e 85%, #282872 99%);

            .api-reference-nav{
                background: linear-gradient(90deg, #140a1e 15%, #140a6e 85%, #282872 99%);

                h1{
                    color:#fff;
                }

                h2{
                    color:#cccccc;
                }

                .nav-item:hover{
                    background-color: rgba($secondary, 0.2);
                    color:#cccccc;
                }
            }
        }
    }

    .privacy-section{
        background: linear-gradient(90deg, rgb(25, 20, 41) 3%, rgb(20, 10, 30) 15%, rgb(20, 10, 110) 85%, rgb(40, 40, 114) 99%);

        .title-cookies-policy, .title-section-cookies-policy, .policy-subtitle{
            color: $secondary;
        }

        .text-below-title{
            color: #dddddd;
        }

        .summary-item{
            color: #dddddd;
        }

        .cookie-policy-paragraph{
            color: #dddddd;

            a{
                color: $secondary;
            }
        }

        .divtable{
            .cookies-policy-table{
                .tbody-text{
                    color: #dddddd;
                }
            }
        }
    }
}

@media only screen and (min-width: 100px){
    .dark-mode-body{
        .vertical-timeline-element {
            &.art{
                .vertical-timeline-element-content.bounce-in{
                    box-shadow: 0px 0px 15px 10px #00000033;
                }
            }
        }
    }
}

/*************END-DARK-MODE***************/

/***************SIGN-UP*****************/

.language-dropdown{
    .text{
        width: 93%;
        padding-top: 5px;

        .item{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
    
            img{
                height: 16px;
                width: 25px;
                margin-right: 10px;
                margin-bottom: 2px;
                border: 1px solid black
            }
        }
    }

    .dropdown.icon{
        top: -3px;
        color: $secondary;
    }

    .ui.dropdown .menu>.item {
        height: 15px;
    }

    .visible.menu.transition{
        width: 100%;
        border-top: 1px solid $secondary;
        border-bottom: 1px solid $secondary;
        border-left: 1px solid $secondary;
        border-right: 1px solid $secondary;

        .selected.item{
            background-color: rgba($secondary, 0.5);
        }

        .item{
            padding: 10px 16px !important;

            .text{
                display: flex;
                flex-direction: row;

                img{
                    height: 15px;
                    width: 25px;
                    margin-right: 10px;
                    border: 1px solid black
                }

                span{
                    padding-top: 1px;
                }
            }
        }

        .item:hover{
            background-color: rgba($secondary, 0.3);
        }

        .selected.item:hover{
            background-color: rgba($secondary, 0.5);
        }
    }
}

@media (max-width: 400px) {
    .language-dropdown{
        .dropdown.icon{
            margin: 0 !important;
        }
    }
}

/**************END-SIGN-UP**************/

/**************LIVE-REPORT**************/

.live-report-container{
    padding: 132px 20px 50px 20px;
    height: 100%;
    max-width: 1700px;
    margin: 0 auto;

    .live-report-title-row{
        margin-bottom: 50px;

        h1{
            color: $primary;
            font-size: 50px;
            padding-bottom: 20px;
            font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
            margin: 5px 0px;
            font-weight: 500;
            line-height: 1.2;
        }

        .sub-title{
            font-size: 22px;
            font-weight: 500;
            color: #000000;
            margin-left: 5px;
        }
        
        .publish-day{
            padding-top: 16px;
            font-size: 12px;
            color: #757575;
            margin-left: 5px;
        }
    }

    .live-report-cards-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0px 80px;
        margin-bottom: 125px;
        
        .real-time-card{
            width: 32%;
            padding: 20px;
            padding-bottom: 10px;
            box-shadow: rgba(88, 102, 126, 0.08) 0px 4px 24px, rgba(88, 102, 126, 0.12) 0px 1px 2px;
            border-radius: 8px;
            height: 200px;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .real-time-card-container{
                display: flex;
                overflow: hidden;
                position: relative;
                width: 100%;

                &.one-section{
                    overflow: visible;
                }

                .real-time-card-section{
                    min-width: 100%;
                    opacity: 0;
                    transition: opacity 0.2s, left 1s;
                    right: 0;
                    left: 100%;
                    position: relative;
                    display: flex;
                    flex-direction: column;

                    &.active{
                        opacity: 1;
                        left: 0;
                        position: absolute;
                    }
    
                    .header{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 20px;
    
                        .title-container{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
    
                            img{
                                width: 20px;
                                height: 20px;
                                margin-right: 8px;
                            }
    
                            h3{
                                color: black;
                                font-size: 18px;
                                font-weight: 600;
                                margin: 0px;
                                margin-top: 5px;
                                padding: 0px;
                            }
                        }

                        span{
                            color: blue;
                            font-size: 12px;
                            font-weight: 600;
                            letter-spacing: 0.5px;
                            margin-top: 5px;
                            cursor: pointer;
                        }

                        .loader-bar{
                            background-color: rgb(239, 242, 245);
                            border-radius: 5px;
                            height: 27px;
                            width: 50%;

                            &.more{
                                width: 15%;
                            }

                            .bar-progress{
                                width: 2%;
                                height: 100%;
                                background-color: #f0efef;
                                transition: width 0.5s ease;
                                border-radius: 5px;
                            }

                            .bar-progress:hover{
                                width: 100%;
                            }
                        }
                    }
    
                    .body{
                        display: flex;
                        flex-direction: column;
                        margin-left: 20px;

                        .body-row{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            margin-bottom: 10px;

                            .title-container{
                                display: flex;
                                flex-direction: row;
                                align-items: center;
        
                                img{
                                    width: 25px;
                                    height: 25px;
                                    margin-right: 10px;

                                    &.flag{
                                        width: 25px;
                                        height: 17px;
                                        border: 1px solid grey;
                                    }
                                }
        
                                h3{
                                    color: black;
                                    font-size: 16px;
                                    font-weight: 600;
                                    margin: 0px;
                                    margin-top: 1px;
                                    padding: 0px;
                                }

                                .title{
                                    margin-top: 0px;
                                }

                                p{
                                    font-size: 13.5px;
                                    margin-right: 15px;
                                }
                            }

                            p{
                                font-size: 17px;
                                font-weight: 600;
                                margin-right: 10px;

                                &.blue {
                                    color: $primary;
                                }

                                &.green {
                                    color: #16c784;
                                }

                                &.yellow {
                                    color: #f5b97f;
                                }

                                &.red{
                                    color: #f9000080;
                                }
                            }

                            .loader-bar{
                                background-color: rgb(239, 242, 245);
                                border-radius: 5px;
                                height: 26px;
                                width: 100%;
    
                                .bar-progress{
                                    width: 2%;
                                    height: 100%;
                                    background-color: #f0efef;
                                    transition: width 0.5s ease;
                                    border-radius: 5px;
                                }
    
                                .bar-progress:hover{
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }

            .buttons{
                display: flex;
                flex-direction: row;
                justify-content: center;

                .button{
                    border: none;
                    width: 8px;
                    height: 8px;
                    background: $primary;
                    border-radius: 8px;
                    margin: 0px 3px;
                    padding: 0px;
                    cursor: default;
                    
                    &.inactive{
                        background: #cfd6e4;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .service-status-row{
        padding: 0px 80px;
        margin-bottom: 30px;

        .divtable {
            width: 100%;
            overflow-x: auto;
            margin-bottom: 20px;
        
            .cookies-policy-table {
                width: auto;
                max-width: 100%;
                margin-bottom: 35px;
                border: 1px solid #e2e0e0;
                margin-left: auto;
                margin-right: auto;
        
                .tr-head {
                    background-color: $primary;
                }
        
                .thead-td-table {
                    padding: 10px 50px 10px 10px;
                }
        
                .tbody-td-table {
                    padding: 10px 50px 10px 10px;

                    &.center{
                        text-align: center;

                        &.icon{
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                        }

                        .icon{
                            display: flex;
                            flex-direction: row;
                            justify-content: center;

                            .close-icon{
                                margin-right: 8px;
                            }
                        }

                        .loader-bar{
                            background-color: rgb(239, 242, 245);
                            border-radius: 5px;
                            height: 26px;
                            width: 100%;
    
                            .bar-progress{
                                width: 2%;
                                height: 100%;
                                background-color: #f0efef;
                                transition: width 0.5s ease;
                                border-radius: 5px;
                            }
    
                            .bar-progress:hover{
                                width: 100%;
                            }
                        }
                    }

                    .loader-bar{
                        background-color: rgb(239, 242, 245);
                        border-radius: 5px;
                        height: 26px;
                        width: 100%;

                        .bar-progress{
                            width: 2%;
                            height: 100%;
                            background-color: #f0efef;
                            transition: width 0.5s ease;
                            border-radius: 5px;
                        }

                        .bar-progress:hover{
                            width: 100%;
                        }
                    }

                    .region{
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        img{
                            width: 20px;
                            height: 20px;
                            margin-right: 8px;

                            &.flag{
                                width: 25px;
                                height: 17px;
                                border: 1px solid grey;
                            }
                        }

                        h3{
                            color: black;
                            font-size: 16px;
                            font-weight: 600;
                            margin: 0px;
                            margin-top: 5px;
                            padding: 0px;
                        }
                    }

                    p{
                        font-size: 17px;
                        margin-right: 10px;

                        &.tbody-text {
                            font-size: 16px;
                            color: #000000;
                        }

                        &.blue {
                            color: $primary;
                            font-weight: 600;
                        }

                        &.green {
                            color: #16c784;
                            font-weight: 600;
                        }

                        &.yellow {
                            color: #f5b97f;
                            font-weight: 600;
                        }

                        &.red{
                            color: #f9000080;
                            font-weight: 600;
                        }
                    }

                    img{
                        width: 18px;
                        height: 18px;
                        margin-right: 10px;
                    }

                    .close-icon{
                        margin-top: 5px;
                        margin-right: 10px;
                        font-size: 20px;
                        color: #f9000080;
                    }
                }
        
                .thead-text {
                    font-weight: 500;
                    font-size: 18px;
                    color: white;
                    line-height: 30px;
                }
            }
        }
    }

    .live-report-charts-row{
        margin-bottom: 100px;
        padding: 80px 50px;
        border-radius: 20px;
        box-shadow: rgba(88, 102, 126, 0.08) 0px 4px 24px, rgba(88, 102, 126, 0.12) 1px 3px 2px;

        .row-title{
            display: flex;
            flex-direction: row;
            margin-bottom: 50px;

            h1{
                font-size: 32px;
                font-weight: 700;
                margin-right: 20px;
            }

            .language-dropdown{
                margin-top: 5px;
                height: 32px;
                background-color: #F7F7F7 !important;
                border: 1px solid #E2E2E2;
                border-color: #E5AC69;
                width: 200px;
                border-radius: 8px;

                .text{
                    width: 85%;
                    padding-top: 5px;
                    padding-left: 10px;
                }

                .visible.menu.transition .item .text{
                    padding-top: 0px;
                    padding-left: 0px;
                }
            }
        }

        .row-chart{
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: 50px;
            
            .chart-container{
                display: flex;
                flex-direction: row;
                align-content: center;
                text-align: center;
                width: 65%;
                min-height: 475px;
                padding: 30px;
                border-radius: 20px;
                box-shadow: rgba(88, 102, 126, 0.08) 0px 4px 24px, rgba(88, 102, 126, 0.12) 1px 3px 2px;
            }            
        }

        .row-chart:last-child{
            margin-bottom: 20px;
        }

        .row-charts{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            margin-bottom: 40px;

            .chart-container{
                width: 30%;
                display: flex;
                align-items: center;
                padding: 30px;
                border-radius: 20px;
                box-shadow: rgba(88, 102, 126, 0.08) 0px 4px 24px, rgba(88, 102, 126, 0.12) 1px 3px 2px;

                &.line{
                    width: 65%;
                }
            }
        }

        .comming-soon-row{
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding-top: 30px;

            .comming-soon-container{
                box-shadow: rgba(88, 102, 126, 0.08) 0px 4px 24px, rgba(88, 102, 126, 0.12) 1px 3px 2px;
                border-radius: 16px;
                max-width: 800px;
                padding: 120px 150px;

                h1{
                    font-size: 50px;
                    font-weight: 500;
                    text-align: center;
                    color: $primary;
                    line-height: 1.1em;
                }

                p{
                    margin-top: 40px;
                    margin-bottom: 20px;
                    letter-spacing: 0.5em;
                    font-size: 15px;
                }

                .bar{
                    height: 13px;
                    display: flex;
                    flex-direction: row;

                    div{
                        width: 75%;
                        background-color: $primary;
                    }

                    div:last-child{
                        width: 25%;
                        background-color: grey;
                    }
                }

                .percentage{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    p{
                        margin-top: 10px;
                        letter-spacing: normal;
                    }
                }
            }
        }
    }

    .live-report-map-row{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 100px;
        padding: 80px 50px;
        border-radius: 20px;
        box-shadow: rgba(88, 102, 126, 0.08) 0px 4px 24px, rgba(88, 102, 126, 0.12) 1px 3px 2px;

        .row-title{
            display: flex;
            flex-direction: row;
            margin-bottom: 50px;

            h1{
                font-size: 32px;
                font-weight: 700;
                margin-right: 20px;
            }
        }

        .map-row{
            display: flex;
            justify-content: center;
            text-align: center;
            
            .map-container{
                width: 100%;
                max-width: 800px;
                border: 5px solid #0d47a1;

                svg{
                    background-color: #d4dadc;
                    cursor: grab;
                }     
            }

            .statuses-container{
                padding: 15px;
                display: flex;
                flex-direction: column;
                margin-left: 20px;

                .status{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    margin-bottom: 30px;

                    .header{
                        display: flex;
                        flex-direction: row;
                        margin-bottom: 15px;


                        button{
                            outline: none;
                            border: 3px solid #3388ff;

                            &.white{
                                background-color: rgba(251, 251, 249, 0.5);
                            }

                            &.green{
                                background-color: rgba(22, 197, 130, 0.5);
                            }

                            &.red{
                                background-color: rgba(190, 100, 100, 0.5);
                            }

                            &.yellow{
                                background-color: rgba(245, 185, 127, 0.5);
                            }
                        }

                        p{
                            color: #6a6972;
                            font-weight: 500;
                        }
                    }

                    .body{
                        margin-left: 15px;

                        p{
                            text-align: left;
                            color: #6a6972;
                            font-size: 14px;
                        }
                    }

                    .status-tooltip{
                        position: absolute;
                        background-color: rgba(#323546, 0.9);
                        height: 0;
                        max-width: 315px;
                        padding: 14px;
                        border-radius: 20px;
                        opacity: 0;
                        //left: ;
                        transform: translateX(-10px);
                        transition: all 0.5s ease-in-out;
                        text-align: center;
    
                        &.show{
                            opacity: 1;
                            transform: translateX(0px);
                            height: auto;
                        }
    
                        p{
                            color: white;
                            font-weight: 600;
                            letter-spacing: 0.35px;
                        }
                    }
                }
            }
        }
    }
}

.tender-notify-tooltip{
    position: absolute;
    background-color: #222222;
    color: white;
    padding: 10px;
    opacity: 0;
    top:0px;
    left: 0px;
    z-index: -5;

    &.visible{
        opacity: 1;
        z-index: 0;
        display: flex;
        flex-direction: column;
        border-radius: 8px;

        &.green{
            background-color: #8dc572;
        }

        &.red{
            background-color: #be6464;
        }

        &.yellow{
            background-color: #f0ad4e;
        }

        &.grey{
            background-color: #222222;
        }
    }

    .header{
        h1{
            font-size: 17px;
            font-weight: 600;
            color: white;
        }
    }

    .body{
        margin-top: 5px;

        p{
            font-size: 14px;
            color: white;
            font-weight: 500;
        }
    }
    
}

/************END-LIVE-REPORT************/

/*************BETA-SCREEN***************/

.beta-screen{
    display: flex;
    flex-flow: column;
    height: 100vh;
    background-color: $primary;

    .beta-screen-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin:auto;

        .logo{
            width: 150px;
        }

        .title{
            font-size: 18px;
            color: white;
            font-weight: 600;
            margin-top: 20px;
            margin-bottom: 35px;
        }

        .description{
            color: #ddd;
        }

        .mobile-description{
            display: none;
        }

        .button{
            margin-top: 35px;
            padding: 10px 25px;
            border: none;
            color: white;
            font-weight: 700;
            box-shadow: 0px 3px 16px 1px rgba(10, 10, 10, 0.29);
            cursor: pointer;
        }
    }
}

@media (max-width: 767px) {
    .beta-screen{
        .beta-screen-container{
            padding-left: 30px;
            padding-right: 30px;
    
            .logo{
                width: 150px;
            }
    
            .title{
                font-size: 18px;
                color: white;
                font-weight: 600;
                margin-top: 20px;
                margin-bottom: 35px;
            }
    
            .description{
                display: none;
            }

            .mobile-description{
                display: block;
                color: #ddd;
                text-align: center;
            }
    
            .button{
                margin-top: 35px;
                padding: 10px 25px;
                border: none;
                color: white;
                font-weight: 700;
                box-shadow: 0px 3px 16px 1px rgba(10, 10, 10, 0.29);
                cursor: pointer;
            }
        }
    }
}


/************END-BETA-SCREEN************/