$primary: #3A3ABF;
$secondary: #E5AC69;
$primary-light: #c6538c;
$secondary-light: #c6538c;
$text-primary: #4D4D4D;
$text-secondary: #989898;
$lines: #E2E2E2;
$background-grey: #F4F4F4;
$disabled: #F4F4F4;
$success: #26b55d;
$error: #D36060;
$button-hover: #0D0DBE;
$button-error-hover: #d82f2f;
$button-secondary-hover: #E89C41;

$new-primary-navbar: #242499;